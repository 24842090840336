import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { message } from "antd";
import confirm from "antd/lib/modal/confirm";
import { AxiosError, AxiosResponse } from "axios";
import API from "constants/API";
import COOKIE from "constants/COOKIE";
import MESSAGES from "constants/MESSAGES";
import Cookies from "js-cookie";
import { axiosAuth } from "lib/axios";
import { ResponseFetch, ResponseLogin, ResponsePermissions } from "types/child";

interface AuthContextType {
  isAuthenticated: boolean;
  setAuthenticated: (isAuthenticated: boolean) => void;
  doLogin: (phoneNumber: string, password: string) => Promise<void>;
  doLogout: () => void;
  permissions: string[];
}

const defaultValue: AuthContextType = {
  isAuthenticated: true,
  setAuthenticated: () => {},
  doLogin: async () => {},
  doLogout: () => {},
  permissions: [],
};

export const AuthContext = createContext<AuthContextType>(defaultValue);

export const AuthContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [isAuthenticated, setAuthenticated] = useState(
    defaultValue.isAuthenticated
  );

  const [permissions, setPermissions] = useState(defaultValue.permissions);

  useEffect(() => {
    const token = Cookies.get(COOKIE.JWT_AUTH);
    if (token) {
      setAuthenticated(true);
      void getPermission(token);
    } else {
      setAuthenticated(false);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    // token ? setAuthenticated(true) : setAuthenticated(false);
  }, [isAuthenticated]);

  async function getPermission(token: string) {
    await axiosAuth
      .get(API.PERMISSION, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse<ResponsePermissions>) => {
        if (response.data.permissions.length) {
          setPermissions(response.data.permissions);
        }
      })
      .catch((error: AxiosError<ResponseFetch>) => {
        void message.error(error.response?.data.message ?? MESSAGES.GLOBAL);
      });
  }

  async function doLogin(phoneNumber: string, password: string) {
    const body = {
      phoneNumber,
      password,
    };
    await axiosAuth
      .post(API.LOGIN, body)
      .then((response: AxiosResponse<ResponseLogin>) => {
        if (response.status === 201) {
          Cookies.set(COOKIE.JWT_AUTH, response.data.token, {
            httpOnly: false,
            secure: false,
            sameSite: "strict",
            path: "/",
          });
          void message.success("Login Successfully");
          setAuthenticated(true);
        } else {
          void message.success(String(response.data.message));
          window.location.replace("verification");
        }
      })
      .catch((error: AxiosError<ResponseFetch>) => {
        void message.error(error.response?.data.message ?? MESSAGES.GLOBAL);
      });
  }

  function doLogout() {
    confirm({
      title: "Do you want to Logout ?",
      icon: <ExclamationCircleOutlined />,
      content: "Click OK if you want to logout",
      onOk() {
        Cookies.remove(COOKIE.JWT_AUTH);
        setAuthenticated(false);
      },
      onCancel() {},
    });
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setAuthenticated,
        doLogin,
        doLogout,
        permissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthContext() {
  const state = useContext(AuthContext);
  if (state === undefined) {
    throw new Error("AuthContext must be used within a DataProvider");
  }
  return state;
}
