import "./index.scss";

import { createElement, PropsWithChildren, useEffect, useState } from "react";

import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Breadcrumb, Drawer, Layout, Menu } from "antd";
import TopBar from "components/TopBar";
import { useAuthContext } from "context/AuthContext";
import { clxsm } from "helper/clxsm";
import { Link } from "react-router-dom";
import routes from "routes";
import { IRoute } from "types";
import useBreadcrumbs from "use-react-router-breadcrumbs";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const LayoutDefault: React.FC<PropsWithChildren> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { permissions } = useAuthContext();

  const [dataRoutes, setDataRoutes] = useState(routes);
  const breadcrumbs = useBreadcrumbs(dataRoutes);

  const checkPermission = (datas: IRoute[], payloads: string[]): IRoute[] => {
    const menus: IRoute[] = [];
    datas.forEach((data) => {
      if (
        payloads.some((v) => v.includes(data.permission)) ||
        data.permission === "public"
      ) {
        menus.push(data);
      }
    });
    return menus;
  };

  useEffect(() => {
    if (permissions) {
      const menus: IRoute[] = checkPermission(routes, permissions);
      menus.forEach((menu) => {
        if (menu.subMenu?.length) {
          menu.subMenu = checkPermission(menu.subMenu, permissions);
        }
      });
      setDataRoutes(menus);
    }
  }, [permissions]);

  const childMenu = (menu: IRoute) => {
    return menu.subMenu && menu.showSubMenu ? (
      <SubMenu key={menu.path} title={menu.name}>
        {menu.subMenu.map((subMenu) => childMenu(subMenu))}
      </SubMenu>
    ) : (
      <Menu.Item key={menu.path}>
        {menu.target ? (
          <a href={menu.target} target="_blank">
            {menu.name}
          </a>
        ) : (
          <Link to={menu.path} onClick={() => setCollapsed(false)}>
            {menu.name}
          </Link>
        )}
      </Menu.Item>
    );
  };

  function Menus() {
    return (
      <Menu
        mode="inline"
        theme="dark"
        defaultSelectedKeys={["/"]}
        selectedKeys={[`/${String(location.pathname.split("/")[1])}`]}
        defaultOpenKeys={[`/${String(location.pathname.split("/")[1])}`]}
        forceSubMenuRender
      >
        {dataRoutes
          .filter((route) => !route.isHide)
          .map((menu: IRoute) => childMenu(menu))}
      </Menu>
    );
  }

  return (
    <Layout hasSider>
      <Drawer
        className="sidebar-mobile"
        placement="left"
        onClose={() => setCollapsed(false)}
        open={collapsed}
      >
        <Menus />
      </Drawer>
      <Sider
        className="sidebar-desktop"
        breakpoint="lg"
        theme="dark"
        trigger={null}
        collapsedWidth={0}
        collapsible
        collapsed={collapsed}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <Menus />
      </Sider>
      <Layout
        className={clxsm("site-layout", collapsed ? "" : "not-collapsed")}
      >
        <Header className="header">
          {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: "trigger",
            onClick: () => setCollapsed(!collapsed),
          })}
          <TopBar />
        </Header>
        <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            {breadcrumbs.map(({ breadcrumb, match }) => (
              <Breadcrumb.Item key={match.url}>{breadcrumb}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          ©2021 Powered By VocaGame
        </Footer>
      </Layout>
    </Layout>
  );
};

export default LayoutDefault;
