import { AxiosError, AxiosResponse } from "axios";
import API from "constants/API";
import { axiosInstance } from "lib/axios";
import useRequest from "lib/useRequest";
import { IDictionary, IResponseAction } from "types";
import { ResponseFetch } from "types/child";

export interface ISetting {
  colors: Colors;
  socialMedia: ISocialMedia[];
  copyright: string;
  logoHeaderUrl: string;
  logoFooterUrl: string;
  logoHeaderUrlDark: string;
  logoFooterUrlDark: string;
  siteBannerUrl: string;
  favicon: string;
  siteTitle: string;
  siteDescription: string;
  siteMeta: ISiteMeta[];
  maintenance: IMaintenance;
  description: string;
}

export interface ISettingForm {
  colors: Colors;
  socialMedia: ISocialMedia[];
  copyright: string;
  logoHeaderUrl: string;
  logoFooterUrl: string;
  logoHeaderUrlDark: string;
  logoFooterUrlDark: string;
  siteBannerUrl: string;
  favicon: string;
  siteTitle: string;
  siteDescription: string;
  siteMeta: ISiteMeta[];
  maintenance: IMaintenance;
  analytic: IAnalytic;
  description: string;
}

export interface ISiteMeta {
  name: string;
  content: string;
  attrs: object;
  tempAttrs?: IDictionary[];
}

export interface ISocialMedia {
  name: string;
  iconUrl: string;
  link: string;
}

export interface Colors {
  generalColor: string;
  // Button
  backgroundPrimary: string;
  backgroundSecondary: string;
  backgroundTopdown: string;
  backgroundCardProduct: string;
  backgroundCardProductItem: string;
  backgroundCardPayment: string;
  backgroundDot: string;
  backgroundButton: string;
  // Text
  textPrimary: string;
  textSecondary: string;
  textInvert: string;
  textTopdown: string;
  textDot: string;
  textButton: string;

  // v3 color
  general: string;
  primaryLight: string;
  primaryDark: string;
  primaryForegroundLight: string;
  primaryForegroundDark: string;
  secondaryLight: string;
  secondaryDark: string;
  secondaryForegroundLight: string;
  secondaryForegroundDark: string;
  cardBackgroundLight: string;
  cardForegroundLight: string;
  cardBackgroundDark: string;
  cardForegroundDark: string;
  [key: string]: string;
}

export interface IMaintenance {
  isDisabled: boolean;
  isOffline: boolean;
  message: string;
}

export interface IAnalytic {
  googleTag: string;
  googleAnalytics: string;
  facebookPixel: string;
  tiktokAnalytics: string;
}

export interface IPayloadColor {
  [key: string]: string;
}

export function useSetting() {
  const { data, error, mutate } = useRequest<ISetting>({
    url: `${API.SETTING_GENERAL}`,
  });
  return {
    setting: data ?? ({} as ISetting),
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}

export async function updateSetting(
  payload: ISettingForm
): Promise<IResponseAction> {
  const response: IResponseAction = {} as IResponseAction;
  await axiosInstance
    .put(`${API.SETTING_GENERAL}`, payload)
    .then((result: AxiosResponse<ResponseFetch>) => {
      response.success = true;
      response.message = result.data.message;
    })
    .catch((error: AxiosError<ResponseFetch>) => {
      response.success = false;
      response.message = error.response?.data.message;
    });
  return response;
}

export async function getPlainSetting(url: string): Promise<ISetting> {
  const response = await fetch(`${url}/v1/setting`);
  return response.json();
}

export async function getPlainSettingGeneral(url: string): Promise<ISetting> {
  const response = await fetch(`${url}/v1/setting/general`);
  return response.json();
}

export function useSettingColor() {
  const { data, error, mutate } = useRequest<string[]>({
    url: `${API.SETTING_GENERAL}/color`,
  });
  return {
    colorKey: data ?? ([] as string[]),
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
}

export async function postSettingColor(
  payload: IPayloadColor
): Promise<IResponseAction> {
  const response: IResponseAction = {} as IResponseAction;
  await axiosInstance
    .post(`${API.SETTING_GENERAL}/colors`, payload)
    .then((result: AxiosResponse<ResponseFetch>) => {
      response.success = true;
      response.message = result.data.message;
    })
    .catch((error: AxiosError<ResponseFetch>) => {
      response.success = false;
      response.message = error.response?.data.message;
    });
  return response;
}
