import axios, { AxiosError } from "axios";
import COOKIE from "constants/COOKIE";
import Cookies from "js-cookie";

export const axiosAuth = axios.create({
  baseURL: "/auth",
});

export const axiosInstance = axios.create({
  baseURL: `/api`,
});

axiosInstance.interceptors.request.use((config) => {
  const token = Cookies?.get(COOKIE.JWT_AUTH);
  if (token) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    config.headers = {
      ...config.headers,
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      Authorization: `Bearer ${token}` ?? "",
    };
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      Cookies.remove(COOKIE.JWT_AUTH);
      return location.reload();
    } else if (error.response?.status === 403) {
      return location.replace("/403");
    }
    return Promise.reject(error);
  }
);
