import "./index.scss";

import { useEffect, useState } from "react";

import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useAuthContext } from "context/AuthContext";
import { useHistory } from "react-router-dom";

type LoginForm = {
  phoneNumber: string;
  password: string;
};

export default function LoginPage() {
  const [isSubmit, setSubmit] = useState(false);
  const { isAuthenticated, doLogin } = useAuthContext();
  const { push } = useHistory();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isAuthenticated && push("/");
  }, [isAuthenticated]);

  async function onFinish(values: LoginForm) {
    setSubmit(true);
    await doLogin(values.phoneNumber, values.password);
    setSubmit(false);
  }

  return (
    <div className="wrapper">
      <div className="logo">
        {/* <img
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          src={`${process.env.REACT_APP_API_ENDPOINT}/media/logo.png`}
          alt="Logo"
        /> */}
      </div>
      <Form
        size="large"
        name="login"
        className="login-form"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="phoneNumber"
          rules={[
            {
              required: true,
              message: "Please input your phone number",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="08123321123"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            { required: true, message: "Please input your Password!" },
            { min: 8, message: "Password at least has 8 characters" },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={isSubmit}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
