import "./index.scss";

import { useEffect, useState } from "react";

import { LockOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import { AxiosError, AxiosResponse } from "axios";
import API from "constants/API";
import COOKIE from "constants/COOKIE";
import MESSAGES from "constants/MESSAGES";
import { useAuthContext } from "context/AuthContext";
import Cookies from "js-cookie";
import { axiosAuth } from "lib/axios";
import { useHistory } from "react-router-dom";
import { ResponseFetch, ResponseLogin } from "types/child";

type VerificationForm = {
  code: string;
};

export default function VerificationPage() {
  const [isSubmit, setSubmit] = useState(false);
  const { push } = useHistory();
  const { isAuthenticated, setAuthenticated } = useAuthContext();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isAuthenticated && push("/");
  }, [isAuthenticated]);

  async function onFinish(values: VerificationForm) {
    setSubmit(true);
    await axiosAuth
      .get(`${API.OTP}/${values.code}`)
      .then((response: AxiosResponse<ResponseLogin>) => {
        Cookies.set(COOKIE.JWT_AUTH, response.data.token);
        void message.success("Login Successfully");
        setAuthenticated(true);
      })
      .catch((error: AxiosError<ResponseFetch>) => {
        void message.error(error.response?.data.message ?? MESSAGES.GLOBAL);
      });
    setSubmit(false);
  }

  return (
    <div className="wrapper">
      <div className="logo">
        {/* <img
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          src={`${process.env.REACT_APP_API_ENDPOINT}/media/logo.png`}
          alt="Logo"
        /> */}
      </div>
      <Form
        size="large"
        name="login"
        className="login-form"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="code"
          rules={[
            { required: true, message: "Please input your Verification Code!" },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Verification Code"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={isSubmit}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
