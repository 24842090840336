import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { AuthContextProvider } from "context/AuthContext";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById("root")!);

root.render(
  <AuthContextProvider>
    <Router>
      <App />
    </Router>
  </AuthContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
