import "./index.scss";

import { useState } from "react";

import { Button, Popover } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { useAuthContext } from "context/AuthContext";

const TopBar: React.FC = () => {
  const [state, setState] = useState({
    isVisible: false,
  });
  const { doLogout } = useAuthContext();

  return (
    <Popover
      className="top-bar"
      placement="bottomRight"
      content={
        <Button type="link" onClick={doLogout}>
          Logout
        </Button>
      }
      trigger="click"
      visible={state.isVisible}
      onVisibleChange={(isVisible) => setState({ isVisible })}
    >
      <Avatar
        style={{ backgroundColor: "#001529", verticalAlign: "middle" }}
        size="large"
      >
        U
      </Avatar>
    </Popover>
  );
};

export default TopBar;
