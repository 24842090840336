import { lazy } from "react";

import { IRoute } from "types";

export default <IRoute[]>[
  {
    name: "Dashboard",
    path: "/dashboard",
    permission: "dashboard",
    component: lazy(() => import("pages/Dashboard")),
  },
  {
    name: "Report",
    path: "/report",
    permission: "report",
    showSubMenu: true,
    subMenu: [
      {
        name: "Product",
        permission: "report",
        path: "/report/product",
        component: lazy(() => import("pages/Report/Product")),
      },
      {
        name: "Top Up",
        permission: "report",
        path: "/report/wallet",
        component: lazy(() => import("pages/Report/TopUp")),
      },
      {
        name: "Membership",
        permission: "report",
        path: "/report/membership",
        component: lazy(() => import("pages/Report/Membership")),
      },
      {
        name: "Users",
        permission: "report",
        path: "/report/users",
        component: lazy(() => import("pages/Report/Users")),
      },
      {
        name: "Voucher",
        permission: "report",
        path: "/report/voucher",
        component: lazy(() => import("pages/Report/Voucher")),
      },
      // {
      //   name: "Monthly",
      //   permission: "report",
      //   path: "/report/monthly",
      //   component: lazy(() => import("pages/Report/Monthly")),
      //   showSubMenu: false,
      //   subMenu: [
      //     {
      //       name: "Create Monthly",
      //       permission: "report",
      //       path: "/report/monthly/create",
      //       component: lazy(() => import("pages/Report/Monthly/Form")),
      //     },
      //     {
      //       name: "Update Monthly",
      //       permission: "report",
      //       path: "/report/monthly/update",
      //       component: lazy(() => import("pages/Report/Monthly/Form")),
      //     },
      //   ],
      // },
    ],
  },
  {
    name: "Order",
    path: "/order",
    permission: "order",
    showSubMenu: true,
    subMenu: [
      {
        name: "Product",
        permission: "order",
        path: "/order/product",
        component: lazy(() => import("pages/Order/Product")),
        subMenu: [
          {
            name: "Product Detail",
            path: "/order/product/detail",
            component: lazy(() => import("pages/Order/Product/Detail")),
          },
        ],
      },
      {
        name: "Top Up",
        path: "/order/topup",
        permission: "order",
        component: lazy(() => import("pages/Order/TopUp")),
        subMenu: [
          {
            name: "Top Up Detail",
            path: "/order/topup/detail",
            component: lazy(() => import("pages/Order/TopUp/Detail")),
          },
        ],
      },
      {
        name: "Membership",
        path: "/order/membership",
        permission: "order",
        component: lazy(() => import("pages/Order/Membership")),
        subMenu: [
          {
            name: "Membership Detail",
            path: "/order/membership/detail",
            component: lazy(() => import("pages/Order/Membership/Detail")),
          },
        ],
      },
    ],
  },
  {
    name: "Provider",
    path: "/provider",
    permission: "provider",
    component: lazy(() => import("pages/Provider")),
    subMenu: [
      {
        name: "Create Provider",
        path: "/provider/create",
        permission: "provider_action",
        component: lazy(() => import("pages/Provider/Form")),
      },
      {
        name: "Update Provider",
        path: "/provider/update",
        permission: "provider_action",
        component: lazy(() => import("pages/Provider/Form")),
      },
    ],
  },
  {
    name: "Category",
    path: "/category",
    permission: "category",
    component: lazy(() => import("pages/Category")),
    subMenu: [
      {
        name: "Create Category",
        path: "/category/create",
        permission: "category_action",
        component: lazy(() => import("pages/Category/Form")),
      },
      {
        name: "Update Category",
        path: "/category/update",
        permission: "category_action",
        component: lazy(() => import("pages/Category/Form")),
      },
    ],
  },
  {
    name: "Product",
    path: "/product",
    permission: "product",
    component: lazy(() => import("pages/Product")),
    subMenu: [
      {
        name: "Product Item",
        path: "/product/item",
        permission: "product_item",
        component: lazy(() => import("pages/Product/Item")),
        subMenu: [
          {
            name: "Create Product Item",
            path: "/product/item/create",
            permission: "product_item_action",
            component: lazy(() => import("pages/Product/Item/Form")),
          },
          {
            name: "Update Product Item",
            path: "/product/item/update",
            permission: "product_item_action",
            component: lazy(() => import("pages/Product/Item/Form")),
          },
        ],
      },
      {
        name: "Product Variant",
        path: "/product/variant",
        permission: "product_variant",
        component: lazy(() => import("pages/Product/Variant")),
        subMenu: [
          {
            name: "Create Product Variant",
            path: "/product/variant/create",
            permission: "product_variant_action",
            component: lazy(() => import("pages/Product/Variant/Form")),
          },
          {
            name: "Update Product Variant",
            path: "/product/variant/update",
            permission: "product_variant_action",
            component: lazy(() => import("pages/Product/Variant/Form")),
          },
        ],
      },
      {
        name: "Create Product",
        path: "/product/create",
        permission: "product_action",
        component: lazy(() => import("pages/Product/Form")),
      },
      {
        name: "Update Product",
        path: "/product/update",
        permission: "product_action",
        component: lazy(() => import("pages/Product/Form")),
      },
    ],
  },
  {
    name: "Payment",
    path: "/payment",
    permission: "payment",
    component: lazy(() => import("pages/Payment")),
    subMenu: [
      {
        name: "Payment Channel",
        path: "/payment/channel",
        permission: "payment_channel",
        component: lazy(() => import("pages/Payment/Channel")),
        subMenu: [
          {
            name: "Update Payment Channel",
            path: "/payment/channel/update",
            permission: "payment_channel_action",
            component: lazy(() => import("pages/Payment/Channel/Form")),
          },
          {
            name: "Update Bank Transfer Channel",
            path: "/payment/channel/bank-transfer/update",
            permission: "payment_channel_action",
            component: lazy(
              () => import("pages/Payment/Channel/Form/BankTransfer")
            ),
          },
          {
            name: "Create Bank Transfer Channel",
            path: "/payment/channel/bank-transfer/create",
            permission: "payment_channel_action",
            component: lazy(
              () => import("pages/Payment/Channel/Form/BankTransfer")
            ),
          },
          {
            name: "Update Manual Transfer Channel",
            path: "/payment/channel/manual-transfer/update",
            permission: "payment_channel_action",
            component: lazy(
              () => import("pages/Payment/Channel/Form/ManualTransfer")
            ),
          },
          {
            name: "Create Manual Transfer Channel",
            path: "/payment/channel/manual-transfer/create",
            permission: "payment_channel_action",
            component: lazy(
              () => import("pages/Payment/Channel/Form/ManualTransfer")
            ),
          },
        ],
      },
    ],
  },
  {
    name: "Banner",
    path: "/banner",
    permission: "banner",
    component: lazy(() => import("pages/Banner")),
    subMenu: [
      {
        name: "Create Banner",
        path: "/banner/create",
        permission: "banner_action",
        component: lazy(() => import("pages/Banner/Form")),
      },
      {
        name: "Update Banner",
        path: "/banner/update",
        permission: "banner_action",
        component: lazy(() => import("pages/Banner/Form")),
      },
    ],
  },
  {
    name: "Voucher",
    path: "/voucher",
    permission: "voucher",
    component: lazy(() => import("pages/Voucher")),
    subMenu: [
      {
        name: "Create Voucher",
        path: "/voucher/create",
        permission: "voucher_action",
        component: lazy(() => import("pages/Voucher/Form")),
      },
      {
        name: "Update Voucher",
        path: "/voucher/update",
        permission: "voucher_action",
        component: lazy(() => import("pages/Voucher/Form")),
      },
    ],
  },
  {
    name: "Media",
    path: "/media",
    permission: "public",
    component: lazy(() => import("pages/Media")),
  },
  {
    name: "Managament",
    path: "/manage",
    permission: "management",
    showSubMenu: true,
    subMenu: [
      {
        name: "User",
        path: "/manage/user",
        permission: "management_user",
        component: lazy(() => import("pages/Management/User")),
        subMenu: [
          {
            name: "Create User",
            path: "/manage/user/create",
            permission: "management_user_action",
            component: lazy(() => import("pages/Management/User/Form")),
          },
          {
            name: "Update User",
            path: "/manage/user/update",
            permission: "management_user_action",
            component: lazy(() => import("pages/Management/User/Form")),
          },
          {
            name: "User Mutation",
            path: "/manage/user/mutation",
            permission: "management_user",
            component: lazy(() => import("pages/Management/User/Mutation")),
          },
          {
            name: "User Auth History",
            path: "/manage/user/auth-history",
            permission: "management_user",
            component: lazy(() => import("pages/Management/User/AuthHistory")),
          },
        ],
      },
      {
        name: "Group",
        path: "/manage/group",
        permission: "management_group",
        component: lazy(() => import("pages/Management/Group")),
        subMenu: [
          {
            name: "Create Group",
            path: "/manage/group/create",
            permission: "management_group_action",
            component: lazy(() => import("pages/Management/Group/Form")),
          },
          {
            name: "Update Group",
            path: "/manage/group/update",
            permission: "management_group_action",
            component: lazy(() => import("pages/Management/Group/Form")),
          },
        ],
      },
      {
        name: "Membership",
        path: "/manage/membership",
        permission: "management_membership",
        component: lazy(() => import("pages/Management/Membership")),
        subMenu: [
          {
            name: "Create Membership",
            path: "/manage/membership/create",
            permission: "management_membership_action",
            component: lazy(() => import("pages/Management/Membership/Form")),
          },
          {
            name: "Update Membership",
            path: "/manage/membership/update",
            permission: "management_membership_action",
            component: lazy(() => import("pages/Management/Membership/Form")),
          },
        ],
      },
    ],
  },
  {
    name: "Vendor",
    path: "/vendor",
    permission: "vendor",
    showSubMenu: true,
    subMenu: [
      {
        name: "Bot",
        path: "/vendor/bot",
        permission: "vendor",
        component: lazy(() => import("pages/Vendor/Bot")),
        subMenu: [
          {
            name: "Create Bot",
            path: "/vendor/bot/create",
            permission: "vendor",
            component: lazy(() => import("pages/Vendor/Bot/Form")),
          },
          {
            name: "Bot Log",
            path: "/vendor/bot/log",
            permission: "vendor",
            component: lazy(() => import("pages/Vendor/BotLog")),
          },
        ],
      },
      {
        name: "Product",
        path: "/vendor/product",
        permission: "vendor",
        component: lazy(() => import("pages/Vendor/Product")),
      },
      {
        name: "Denom",
        path: "/vendor/denom",
        permission: "vendor",
        component: lazy(() => import("pages/Vendor/Denom")),
        subMenu: [
          {
            name: "Create Denom",
            path: "/vendor/denom/create",
            permission: "vendor",
            component: lazy(() => import("pages/Vendor/Denom/Form")),
          },
          {
            name: "Update Denom",
            path: "/vendor/denom/update",
            permission: "vendor",
            component: lazy(() => import("pages/Vendor/Denom/Form")),
          },
        ],
      },
      {
        name: "Midman",
        path: "/vendor/midman",
        permission: "vendor",
        component: lazy(() => import("pages/Vendor/Midman")),
        subMenu: [
          {
            name: "Create Midman",
            path: "/vendor/midman/create",
            permission: "vendor",
            component: lazy(() => import("pages/Vendor/Midman/Form")),
          },
          {
            name: "Update Midman",
            path: "/vendor/midman/update",
            permission: "vendor",
            component: lazy(() => import("pages/Vendor/Midman/Form")),
          },
        ],
      },
    ],
  },
  {
    name: "Article",
    path: "/article",
    permission: "public",
    component: lazy(() => import("pages/Article")),
    subMenu: [
      {
        name: "Create Article",
        path: "/article/create",
        permission: "public",
        component: lazy(() => import("pages/Article/Form")),
      },
      {
        name: "Update Article",
        path: "/article/update",
        permission: "public",
        component: lazy(() => import("pages/Article/Form")),
      },
    ],
  },
  {
    name: "Review",
    path: "/review",
    permission: "public",
    component: lazy(() => import("pages/Review")),
  },
  {
    name: "Page",
    path: "/page",
    permission: "page",
    component: lazy(() => import("pages/Page")),
    subMenu: [
      {
        name: "Create Page",
        path: "/page/create",
        permission: "page_action",
        component: lazy(() => import("pages/Page/Form")),
      },
      {
        name: "Update Page",
        path: "/page/update",
        permission: "page_action",
        component: lazy(() => import("pages/Page/Form")),
      },
    ],
  },
  {
    name: "Setting",
    path: "/setting",
    permission: "setting",
    showSubMenu: true,
    subMenu: [
      {
        name: "General",
        path: "/setting/general",
        permission: "setting",
        component: lazy(() => import("pages/Setting/General")),
      },
      {
        name: "System",
        path: "/setting/system",
        permission: "setting",
        component: lazy(() => import("pages/Setting/System")),
      },
      {
        name: "Whatsapp",
        path: "/setting/whatsapp",
        permission: "setting",
        component: lazy(() => import("pages/Setting/Whatsapp")),
      },
      {
        name: "API KEY",
        path: "/setting/api-key",
        permission: "setting",
        component: lazy(() => import("pages/Setting/APIKey")),
      },
      {
        name: "Banner",
        path: "/setting/banner",
        permission: "setting",
        component: lazy(() => import("pages/Setting/Banner")),
      },
    ],
  },
  {
    name: "Tools",
    path: "/tools",
    permission: "public",
    showSubMenu: true,
    subMenu: [
      {
        name: "Whatsapp",
        path: "/tools/whatsapp",
        permission: "public",
        component: lazy(() => import("pages/Tools/Whatsapp")),
      },
    ],
  },
  {
    name: "Dokumentasi Panel",
    permission: "public",
    path: "#",
    target: "https://docspanel.vocagame.com/docs-panel-game/",
  },
];
